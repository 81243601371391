import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "../../ui";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2 pt-lg-0 pt-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>About Us</h4>
              </div>
            </div>
          </div>

          <div className="row align-items-center comman_space">
            <div className="col-lg-7 order-lg-1 order-2">
              <div className="commanOther">
                <h4>Regular Content Updates</h4>
                <p>
                  Our commitment to keeping the adventure fresh and exciting is
                  reflected in our regular content updates. We continually
                  introduce new quests, events, and features that enhance
                  gameplay and provide players with fresh challenges to tackle.
                  This dedication to expanding the game ensures that there’s
                  always something new to explore, whether it’s uncovering
                  hidden secrets or engaging in limited-time events. With
                  ongoing support from our development team, players can look
                  forward to a dynamic gaming experience that evolves and grows
                  over time.
                </p>
              </div>
              <div className="commanOther">
                <h4>Stunning Visuals & Soundtrack</h4>
                <p>
                  Step into a breathtaking realm filled with stunning visuals
                  that bring our world to life. Every environment, from lush
                  forests to towering mountains, has been meticulously crafted
                  to create an immersive experience that captivates your senses.
                </p>
                <p>
                  Accompanying these visuals is an enchanting soundtrack that
                  enhances the atmosphere and pulls you deeper into the
                  adventure. Together, the artistry of our graphics and the
                  emotive power of our music create a rich sensory experience,
                  making every moment spent in the game unforgettable.
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-2 order-1 text-lg-end text-center my-2">
              <Icon name="aboutus1" className="img-fluid aboutus1_img" />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-5 text-lg-start text-center my-2">
              <Icon name="aboutus2" className="img-fluid aboutus1_img" />
            </div>
            <div className="col-lg-7">
              <div className="commanOther">
                <h4> Flexible Play Styles</h4>
                <p>
                  We understand that every player has their own unique way of
                  enjoying a game. That’s why our fantasy world offers a variety
                  of play styles to accommodate your preferences. Whether you
                  thrive in solo adventures, enjoy cooperative gameplay with
                  friends, or prefer competitive challenges against others, our
                  game adapts to fit your needs. This flexibility allows you to
                  forge your path and engage with the content that excites you
                  most, ensuring that your journey is tailored to your gaming
                  style.
                </p>
              </div>
              <div className="commanOther">
                <h4>Fair Monetization Practices</h4>
                <p>
                  Our game is designed with players in mind, prioritizing
                  fairness and accessibility. We believe that every player
                  should have the opportunity to experience the full adventure
                  without being hindered by paywalls.
                </p>
                {/* <p>
                  Our monetization model is transparent and balanced, allowing
                  players to enjoy additional content and enhancements without
                  compromising the core gameplay experience. This commitment to
                  fair practices ensures that all players, regardless of their
                  spending habits, can immerse themselves in the magical world
                  we’ve created.
                </p> */}

                <p>
                  For additional information, please{" "}
                  <NavLink to={"/contact-us"}>contact us.</NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
