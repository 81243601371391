import React from "react";
import { Icon } from "../../ui";
import { accordionFullData, journeyData, whyChooseData } from "./mock";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../ui/elements/Button";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="lightbg position-relative hero-sec">
          <div className="container">
            <div className="scale-in-ver-top">
              <div className="row justify-content-center text-center">
                <div className="col-lg-8 col-md-10">
                  <h4>
                    <span></span>Welcome to Our Platform
                  </h4>
                  <h1>
                    Enter a World of Myth, Magic, and Legendary Adventures
                  </h1>
                  <p>
                    Embark on epic quests, conquer mythical creatures, and
                    explore vast enchanted realms. Forge your destiny in a land
                    where heroes rise, and legends are born.
                  </p>
                  <div className="mybtn my-2">
                    <Button
                      className="primarybtn"
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                  <div className="heroimg">
                    <Icon name="Heroimg" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="start_withmain comman_space comman_radius" id="usp">
        <div className="container">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>Discover Your Next Adventure!</h4>
                <p>
                  Unleash epic quests, legendary creatures, and limitless
                  customization. Your adventure begins now!
                </p>
              </div>
            </div>
          </div>
          <div className="start_withboxmain">
            {journeyData?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="start_withbox"
                  // style={{ animationDelay: `${index + 0.1}s` }}
                >
                  <div className="start_withicon">
                    <Icon name={data?.icon} className="img-fluid" />
                  </div>
                  <div>
                    <h5>{data?.title}</h5>
                    <p>{data?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container">
        <section id="about" className="comman_space">
          <div className="row align-items-center maximum_profirsec">
            <div className="col-lg-7">
              <div className="aboutus_text">
                <h4>Crafting Legendary Adventures Together</h4>
                <p>
                  At Awriabindia, we are a team of passionate storytellers and
                  game developers united by our love for fantasy and adventure.
                  Our mission is to craft immersive gaming experiences that
                  transport players into vibrant worlds filled with magic,
                  mystery, and legendary quests. With every project, we strive
                  to blend innovative gameplay mechanics with captivating
                  narratives, ensuring that every player feels like the hero of
                  their own epic tale.
                </p>
                <p>
                  Our games are shaped by the feedback and ideas of our
                  dedicated players, and we actively engage with our audience to
                  create experiences that resonate. Join us as we continue to
                  push the boundaries of storytelling and gameplay, inviting you
                  to explore uncharted realms and embark on unforgettable
                  adventures. Together, let's forge new legends!
                </p>
                <div className="pt-2 d-block">
                  <NavLink
                    to={"/about-us"}
                    className="primarybtn d-inline-block"
                  >
                    Know more
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-5 ">
              <div className="aboutus_img my-2">
                <Icon name="aboutus" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        {/* <section id="contact">
          <div className="contact_main">
            <div className="d-md-flex justify-content-between align-items-center d-block">
              <div className="contact_text">
                <h4>Contact Us</h4>
                <p>Get in touch with our team to learn more about</p>
                <Button className="primarybtn">Connect With Us</Button>
              </div>
              <div className="contact_icon">
                <Icon name="earn2" className="img-fluid" />
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="categories_swiper">
          <Swiper
            centeredSlides={false}
            spaceBetween={20}
            speed={900}
            parallax
            rewind
            loop
            effect="slide"
            freeMode
            grabCursor={true}
            watchSlidesProgress
            slidesPerView={3}
            breakpoints={{
              1200: { slidesPerView: 4 },
              992: { slidesPerView: 3 },
              768: { slidesPerView: 3 },
              576: { slidesPerView: 2 },
              0: { slidesPerView: 1.5 },
            }}
            autoplay={{
              delay: 19000,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {clientReview?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="category_card ">
                    <Icon name="earn1" className="" />
                    <div className="play_cardtext">
                      <h4>{data?.title}</h4>
                      <p>{data?.productNo}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
      </div>

      <section id="whychoose" className="comman_space enjoybg comman_radius">
        <div className="container">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Why <span>Adventure with </span> us ?
                </h4>
                <p>
                  Experience thrilling gameplay and a vibrant community that
                  makes every adventure unforgettable!
                </p>
              </div>
            </div>
          </div>
          <div className="why_chooseboxmain">
            {whyChooseData.map((item, index) => (
              <div className="why_choosebox">
                <div className="whychoose_icon">
                  <Icon name={item?.icon} className="img-fluid" />
                </div>
                <div>
                  <h5>{item?.title}</h5>
                  <p>{item?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 ">
              <div className="commantext text-center">
                <h4>
                  Common Queries and
                  <span>Answers </span>
                </h4>
                <p>
                  Find answers to common questions about your essential guide to
                  gameplay and adventure insights
                </p>
              </div>
              <div
                className="accordion accordion-flush selfaccord"
                id="accordionFlushExample"
              >
                {accordionFullData?.slice(0, 6)?.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={item.id}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}1`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}1`}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}1`}
                      className="accordion-collapse collapse"
                      aria-labelledby={item.id}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
