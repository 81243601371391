import React, { useState } from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import apk from "../../apk/Awriabindia.apk";
import {
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
} from "react-icons/io5";

const Footer = () => {
  const [activeSection, setActiveSection] = useState("  ");
  const navigate = useNavigate();

  const handleLinkClick = (link) => {
    navigate("/");
    setTimeout(() => {
      setActiveSection(link);
      scrollToSection(link);
    }, 0);
  };

  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const contactInfo = [
    {
      href: "tel:+919910985059",
      icon: <IoCallOutline />,
      text: "+91 9910985059",
    },
    {
      href: "mailto:infoawriabindia@gmail.com",
      icon: <IoMailOutline />,
      text: "infoawriabindia@gmail.com",
    },
    {
      href: "https://maps.app.goo.gl/fzgQ85kLk3frC1mq6",
      icon: <IoLocationOutline />,
      text: "A-27 First Floor,Old Plot No. 35&36,Guru Nanak Pura Laxmi Naghar,New Delhi, Shahdara, Delhi, 110092",
    },
  ];

  return (
    <>
      <section>
        <div className="container">
          <div className="get_app">
            <div>
              <h4>Get the App Now!</h4>
              <p>
                Download our app now to enjoy seamless cricket fantasy gaming,
                real-time updates, and exciting rewards. Stay connected and
                never miss out on the action. Get it today!
              </p>
            </div>
            <div>
              <a
                className="lenierbtn"
                href={apk} download="Awriabindia.apk"
              >
                Download App Free
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-5 col-md-6 col-sm-6 my-md-2 my-sm-1">
                <div className="link-detail">
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid mb-3" />
                  </p>
                  <p>
                    Owned and operated by AWRIAB WALL INDIA (OPC) PRIVATE
                    LIMITED. Awriabindia.com is an online fantasy game designed
                    for the fans who like to showcase their cricket knowledge
                    and analytical skills.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 my-md-2 my-sm-1">
                <div className="link-detail">
                  <h6>Contact Info</h6>
                </div>
                <div>
                  {contactInfo.map((item, index) => (
                    <div key={index} className="followus">
                      <span>
                        <a href={item.href}>{item.icon}</a>
                      </span>
                      <h5>
                        <a href={item.href}>{item.text}</a>
                      </h5>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container link-detail footer_links">
          <p
            onClick={() => {
              handleLinkClick("home");
            }}
          >
            Home
          </p>
          <p
            onClick={() => {
              handleLinkClick("usp");
            }}
          >
            Features
          </p>
          <p>
            <p
              onClick={() => {
                handleLinkClick("whychoose");
              }}
            >
              Why Choose Us?
            </p>
          </p>
          <p>
            <NavLink to={"/about-us"}>About us</NavLink>
          </p>
          <p>
            <NavLink to={"/contact-us"}>Contact Us</NavLink>
          </p>
          <p>
            <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
          </p>
          <p>
            <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
          </p>
          <p>
            <NavLink to={"/refund-return"}>Refund & Cancellation</NavLink>
          </p>
        </div>
      </footer>
      <div className="copy-rightMain ">
        <div className="container copy-right">
          <p>Copyright © 2024 Awriabindia</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
