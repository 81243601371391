export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Epic Quests & Storylines",
    description:
      "Embark on intricate, immersive storylines filled with challenging quests that take you deeper into a world of magic and mystery.",
  },
  {
    icon: "tradingItemTwo",
    title: "Mythical Creatures & Bosses",
    description:
      "Face off against legendary creatures and powerful bosses, each with unique abilities that test your skills and strategy.",
  },
  {
    icon: "tradingItemThree",
    title: "Hero Customization",
    description:
      "Create and customize your hero with a wide range of abilities, weapons, armor, and powers to build your ultimate warrior.",
  },
  {
    icon: "tradingItemFour",
    title: "Open World Exploration",
    description:
      "Traverse vast, beautifully designed environments, from enchanted forests to ancient ruins, filled with secrets waiting to be discovered.",
  },
  {
    icon: "tradingItemFive",
    title: "Multiplayer Adventures",
    description:
      "Join forces with friends or battle other players in multiplayer modes, where alliances and rivalries shape your journey.",
  },
  {
    icon: "tradingItemSix",
    title: "Magical Artifacts & Loot",
    description:
      "Collect rare magical items, powerful artifacts, and treasure as you explore, each enhancing your abilities and empowering your hero.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "choose1",
    title: "Innovative Gameplay Mechanics",
    description:
      "Engage with cutting-edge mechanics that bring battles and exploration to life, ensuring an exhilarating experience every time.",
  },
  {
    icon: "choose2",
    title: "Rich Lore & Backstory",
    description:
      "Dive deep into an expansive lore that adds depth and meaning to your journey, making every quest feel significant.",
  },
  {
    icon: "choose3",
    title: "Active Community Support",
    description:
      "Join a vibrant community of players and developers who are passionate, supportive, and always ready to help you thrive.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Awriabindia?",
    content:
      "Awriabindia is a fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Awriabindia?",
    content:
      "Download the Awriabindia app from the App Store or Google Play, create an account, and join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "Are there multiplayer features in Awriabindia?",
    content: `Team up with friends or join forces with players worldwide to tackle challenges and explore the expansive realms together.
`,
  },
  {
    id: "flush-headingFive",
    title: "What kind of quests can I expect?",
    content: `Engage in thrilling quests that range from epic battles against legendary foes to intricate puzzles that unlock the secrets of Awriabindia.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
